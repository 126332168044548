<template>
  <div class="bg-white wrap flex justify-center">
    <div class="sm:bg-sec-bg main-wrap flex justify-center">
      <div
        class="xxl:max-w-screen-xxl w-full xxl:mx-auto flex flex-col items-center"
      >
        <div
          class="header-wrap py-5 font-medium sm:flex items-center place-self-start w-full xxl:pl-10x1"
        >
          <div class="text-secondary text-3x1 logo">
            Loan<span class="text-primary">Spot</span>
          </div>
        </div>
        <div class="form-wrap mt-12">
          <div
            lass="sm:px-8 bg-white rounded-lg sm:pt-12 sm:pb-8 "
            class="max-w-xl bg-white sm:py-16 sm:px-20 sm:shadow-loan rounded-lg mx-auto sm:pt-12 sm:pb-8"
          >
            <router-link
              to="/get-loan/amount"
              class="hidden sm:block h-12 w-12 absolute mt-8 -ml-6"
            >
              <svg
                width="11"
                height="18"
                viewBox="0 0 11 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.7068 16.2929L9.29257 17.7071L0.585467 9.00001L9.29257 0.292908L10.7068 1.70712L3.41389 9.00001L10.7068 16.2929Z"
                  fill="#B5B5B5"
                />
              </svg>
            </router-link>
            <h3 class="pt-6 pb-10 font-medium working--title sm:text-center">
              Is this loan for you or a business?
            </h3>
            <form @submit.prevent="goTo" class="">
              <div class="radio-wrap">
                <label
                  ref="strongAgreeLabel"
                  for="s-agree"
                  class="cursor-pointer text-textColor h-12 bg-radio-bg rounded border border-inputBg hover:border-secondary flex items-center justify-center text-sm mb-3"
                >
                  <svg
                    class="radio-img hidden"
                    ref="strongAgreeIcon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.99997 15.586L6.70697 12.293L5.29297 13.707L9.99997 18.414L19.707 8.70697L18.293 7.29297L9.99997 15.586Z"
                      fill="#2C479C"
                    />
                  </svg>
                  Just Me
                  <input
                    @change="setBg()"
                    ref="strongAgree"
                    id="s-agree"
                    class="hidden"
                    type="radio"
                    name="working"
                  />
                </label>
              </div>
              <div class="radio-wrap">
                <label
                  ref="agreeLabel"
                  for="agree"
                  class="cursor-pointer text-textColor h-12 bg-radio-bg rounded border border-inputBg hover:border-secondary flex items-center justify-center text-sm mb-10"
                >
                  <svg
                    class="radio-img hidden"
                    ref="agreeIcon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.99997 15.586L6.70697 12.293L5.29297 13.707L9.99997 18.414L19.707 8.70697L18.293 7.29297L9.99997 15.586Z"
                      fill="#2C479C"
                    />
                  </svg>
                  My Business
                  <input
                    @change="setBg()"
                    ref="agree"
                    id="agree"
                    class="hidden"
                    type="radio"
                    name="working"
                  />
                </label>
              </div>

              <div class="grid gap-4 grid-cols-2 sm:block">
                <router-link
                  to="/get-loan/amount"
                  class="back--link flex sm:hidden px-4 bg-input-bg text-secondary border border-inputBorder2 rounded-md h-12 font-medium text-sm items-center justify-center"
                >
                  Back
                </router-link>
                <Button>
                  <span id="spin" slot="spin" class="animate-spin hidden">
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 0C13.6228 0 13.3171 0.305746 13.3171 0.682927V7.51219C13.3171 7.88934 13.6228 8.19512 14 8.19512C14.3772 8.19512 14.6829 7.88934 14.6829 7.51219V0.682927C14.6829 0.305746 14.3772 0 14 0ZM4.58841 3.90549C4.41362 3.90549 4.2309 3.96421 4.09756 4.09756C3.83084 4.36427 3.83084 4.80188 4.09756 5.0686L8.93139 9.89177C9.19807 10.1584 9.62503 10.1585 9.89178 9.89177C10.1585 9.62506 10.1584 9.19808 9.89178 8.9314L5.06861 4.09756C4.93527 3.96421 4.76321 3.90549 4.58841 3.90549ZM23.4116 3.90549C23.2368 3.90549 23.0648 3.96421 22.9314 4.09756L18.1082 8.9314C17.8416 9.19809 17.8416 9.62507 18.1082 9.89177C18.3749 10.1585 18.8019 10.1585 19.0686 9.89177L23.9024 5.0686C24.1692 4.80189 24.1691 4.36426 23.9024 4.09756C23.7691 3.96421 23.5863 3.90549 23.4116 3.90549ZM0.682927 13.3171C0.305746 13.3171 0 13.6228 0 14C0 14.3772 0.305746 14.6829 0.682927 14.6829H7.51219C7.88934 14.6829 8.19512 14.3772 8.19512 14C8.19512 13.6228 7.88934 13.3171 7.51219 13.3171H0.682927ZM20.4878 13.3171C20.1107 13.3171 19.8049 13.6228 19.8049 14C19.8049 14.3772 20.1107 14.6829 20.4878 14.6829H27.3171C27.6943 14.6829 28 14.3772 28 14C28 13.6228 27.6943 13.3171 27.3171 13.3171H20.4878ZM9.41158 17.9055C9.23682 17.9055 9.06473 17.9749 8.93139 18.1082L4.09756 22.9314C3.83084 23.1981 3.83088 23.6357 4.09756 23.9024C4.36428 24.1692 4.80186 24.1692 5.06861 23.9024L9.89178 19.0686C10.1584 18.8019 10.1584 18.3749 9.89178 18.1082C9.75841 17.9749 9.58635 17.9055 9.41158 17.9055ZM18.5884 17.9055C18.4136 17.9055 18.2416 17.9749 18.1082 18.1082C17.8415 18.3749 17.8416 18.8019 18.1082 19.0686L22.9314 23.9024C23.1981 24.1692 23.6358 24.1692 23.9024 23.9024C24.1692 23.6357 24.1692 23.1981 23.9024 22.9314L19.0686 18.1082C18.9353 17.9749 18.7632 17.9055 18.5884 17.9055ZM14 19.8049C13.6228 19.8049 13.3171 20.1107 13.3171 20.4878V27.3171C13.3171 27.6943 13.6228 28 14 28C14.3772 28 14.6829 27.6943 14.6829 27.3171V20.4878C14.6829 20.1107 14.3772 19.8049 14 19.8049Z"
                        fill="white"
                        fill-opacity="0.7"
                      />
                    </svg>
                  </span>
                  <span id="check" slot="check" class="hidden mr-1">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.00016 13.6666C3.31816 13.6666 0.333496 10.6819 0.333496 6.99992C0.333496 3.31792 3.31816 0.333252 7.00016 0.333252C10.6822 0.333252 13.6668 3.31792 13.6668 6.99992C13.6668 10.6819 10.6822 13.6666 7.00016 13.6666ZM7.00016 12.3333C8.41465 12.3333 9.77121 11.7713 10.7714 10.7712C11.7716 9.77096 12.3335 8.41441 12.3335 6.99992C12.3335 5.58543 11.7716 4.22888 10.7714 3.22868C9.77121 2.22849 8.41465 1.66659 7.00016 1.66659C5.58567 1.66659 4.22912 2.22849 3.22893 3.22868C2.22873 4.22888 1.66683 5.58543 1.66683 6.99992C1.66683 8.41441 2.22873 9.77096 3.22893 10.7712C4.22912 11.7713 5.58567 12.3333 7.00016 12.3333ZM6.3355 9.66659L3.50683 6.83792L4.4495 5.89525L6.3355 7.78125L10.1062 4.00992L11.0495 4.95259L6.3355 9.66659Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span id="next" slot="value"> Next </span>
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../components/shared/button";
import { GET_LOAN_ROUTES } from "../../constants";

export default {
  name: "LoanPerson",
  components: {
    Button,
  },
  props: [],
  data() {
    return {
      great: 'Great!'
    };
  },
  // async mounted() {
  //     await this.getAnalytics();
  // },
  methods: {
    setBg() {
      const strongAgreeLabel = this.$refs.strongAgreeLabel;
      const agreeLabel = this.$refs.agreeLabel;
      if (this.$refs.strongAgree.checked) {
        // this.$refs.strongAgreeIcon.classList.remove("hidden");
        strongAgreeLabel.classList.add("bg-select-bg");
        strongAgreeLabel.classList.add("border-secondary");
        strongAgreeLabel.classList.remove("bg-radio-bg");

        // this.$refs.agreeIcon.classList.add("hidden");
        agreeLabel.classList.remove("bg-select-bg");
        agreeLabel.classList.remove("border-secondary");
        agreeLabel.classList.add("bg-radio-bg");
      } else if (this.$refs.agree.checked) {
        // this.$refs.strongAgreeIcon.classList.add("hidden");
        strongAgreeLabel.classList.remove("bg-select-bg");
        strongAgreeLabel.classList.remove("border-secondary");
        strongAgreeLabel.classList.add("bg-radio-bg");

        // this.$refs.agreeIcon.classList.remove("hidden");
        agreeLabel.classList.add("bg-select-bg");
        agreeLabel.classList.add("border-secondary");
        agreeLabel.classList.remove("bg-radio-bg");
      } else {
        return false;
      }
    },
    goTo(e) {
      e.preventDefault();
      document.getElementById("spin").classList.remove("hidden");
      document.getElementById("next").classList.add("hidden");
      setTimeout(() => {
        document.getElementById("spin").classList.add("hidden");
        document.getElementById("next").classList.remove("hidden");
        document.getElementById("next").innerText = this.great;
        document.getElementById("check").classList.remove("hidden");
        setTimeout(() => {
          window.location.assign(GET_LOAN_ROUTES.PURPOSE);
        }, 2000);
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  min-height: 100vh;
  @media (min-width: 640px) {
    background-image: url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg"),
      url("/assets//images/bg-blue-small.svg");
    background-repeat: no-repeat;
    background-position: -91px -229px, -91px 138px, -91px 505px, -91px 872px,
      238px -320px, 238px 47px, 238px 414px, 238px 781px, 567px -177px,
      567px 190px, 567px 557px, 567px 924px, 896px -28px, 896px 339px,
      896px 706px, 1225px -271px, 1225px 96px, 1225px 463px, 1225px 830px,
      1554px -183px, 1554px 184px, 1554px 551px, 1554px 918px, 1883px -50px,
      1883px 317px, 1883px 684px, 2212px -146px, 2212px 221px, 2212px 588px,
      2212px 955px;
  }
}
.main-wrap {
  padding: 0 10% 80px;
  width: 100%;
  // @media (min-width: 1440px) {
  //   padding: 0 10%;
  // }
}
.logo {
  font-family: EuclidCircularSemiB;
  font-size: 1.5rem;
}
.form-wrap {
  width: 100%;
  max-width: 550px;
}
.working--title {
  font-family: EuclidCircularMed;
  font-size: 1.125rem;
}
.radio-wrap {
  label {
    font-family: EuclidCircularMed;
    height: 82px;
    @media (min-width: 640px) {
      height: 95px;
    }
  }
}
.back--link {
  font-family: EuclidCircularMed;
}
</style>